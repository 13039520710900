import React, { useEffect } from 'react';
import CustomBreadcrumb from '../components/CustomBreadcrumb';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const Family = ({ pageContext, location }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	const handleFakeFocus = () => {
		document.querySelector('.Step.active input').focus = function () {
			console.log('fake focus function');
		};
	}

	const handleLastStepQuestionnaire = () => {
		window.location.href = `https://www.hear.com/d/success/${window.location.search}`
	}

	useEffect(() => {
		const fomrclientScript = document.createElement('script');

		fomrclientScript.setAttribute('id', 'formclient');
		fomrclientScript.src = 'https://formclient.cdn.audibene.net/v2/js/app.js';
		fomrclientScript.classList.add('ClientInjector');
		fomrclientScript.classList.add('FormClientInjector');
		fomrclientScript.dataset.id = 'd05470e0-df92-42e7-a9f8-8dcbb99f625e';
		fomrclientScript.dataset.offerType = 'generic';
		fomrclientScript.dataset.cl = 'v2';

		document.querySelector('.js-questionnaire').appendChild(fomrclientScript);

		window.addEventListener('FormClientRendered', handleFakeFocus);
		window.addEventListener('lastStepQuestionnaire', handleLastStepQuestionnaire);
		
		return () => {
			window.removeEventListener('FormClientRendered', handleFakeFocus);
			window.removeEventListener('lastStepQuestionnaire', handleLastStepQuestionnaire);
		};
	}, []);

	return (
		<Layout>
			<Seo title="Exclusive Friends & Family Offer" />
			<div className="o-row-6p5">
				<section className="c-fam-hero">
					<div className="o-wrapper">
						<h1 className="c-fam-title">
							<span>Exclusive Friends & Family Offer</span> Enjoy a no-risk trial with 20% off
						</h1>
					</div>
				</section>
			</div>

			<section className="o-row-6p5">
				<div className="o-wrapper">
					<div className="o-row-2p5">
						<CustomBreadcrumb location={location} crumbs={crumbs} />
					</div>
					<div className="c-fam-side-by-side">
						<div className="c-fam-side-by-side__left">
							<div className="o-row-2">
								<p className="c-fam-content-title c-fam-content-title--alt">
									Try <span className="u-text-italic">smaller, smarter hearing aids</span> and hear everything life has
									to offer.
								</p>
							</div>
							<div className="o-row-2">
								<ul className="c-fam-list u-color-brand c-fam-list--checkmark">
									<li>Crystal Clear Speech Understanding</li>
									<li>Qi Wireless Recharging Technology</li>
									<li>Virtually Invisible, Award-Winning Design</li>
								</ul>
							</div>
						</div>
						<div className="c-fam-side-by-side__right o-flush-mobile">
							<picture>
								<source
									media="(min-width: 769px)"
									srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/family/content-ha-desk.jpg"
								/>
								<source
									media="(max-width: 768px)"
									srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/family/content-ha.jpg"
								/>
								<img
									className="o-fluid-img"
									srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/family/content-ha-desk.jpg"
									alt="hearing aids"
									loading="lazy"
								/>
							</picture>
						</div>
					</div>
				</div>
			</section>

			<section className="o-row-6p5">
				<div className="o-wrapper ">
					<div className="c-fam-side-by-side">
						<div className="c-fam-side-by-side__left">
							<div className="o-row-4p5">
								<div className="o-row-2">
									<p className="c-fam-content-title">By invitation only!</p>
								</div>
								<div className="o-row-2">
									<p className="c-fam-copy c-fam-copy--semi-bold">
										You’re invited to take advantage of our special one-of-a-kind offer that’s only available to friends
										and family of hear.com employees.
									</p>
								</div>
								<div className="o-row-2 ">
									<p className="c-fam-copy">
										Everyday we get to experience how hearing aids change the lives of our customers, friends and
										families. And at hear.com, we’re more than teammates. We’re family. So as part of our extended
										family, we want YOU and those around you to reap the benefits, too!
									</p>
								</div>
							</div>
							<div className="o-row-2">
								<p className="c-fam-content-title">Exclusive benefits</p>
							</div>
							<div className="o-row-2">
								<p className="c-fam-copy c-fam-copy--semi-bold u-color-brand">
									We’ve put together a premier package for you that includes:
								</p>
							</div>
							<div className="o-row-2">
								<ul className="c-fam-list u-color-brand">
									<li>20% discount on your purchase</li>
									<li>36-60 month financing available</li>
									<li>45-day, no-risk trial with 100% money-back guarantee</li>
								</ul>
							</div>
							<div className="o-row-0">
								<p className="c-fam-copy">
									We can’t wait for you to also experience the wonders of modern hearing technology firsthand, so you
									can live your best life full of sound and joy.
								</p>
							</div>
						</div>
						<div className="c-fam-side-by-side__right js-questionnaire" />
					</div>
				</div>
			</section>

			<section className="o-row-6p5">
				<div className="o-wrapper ">
					<div className="o-row-2">
						<p className="c-fam-content-title">How it works</p>
					</div>
					<div className="o-row">
						<ol className="c-fam-list">
							<li>
								Simply <strong>fill out the form</strong> on this page and let us know which member of our hear.com team
								referred you. One of our hearing experts will call you soon to find a tailor-made solution that fits
								your hearing needs, lifestyle and budget.
							</li>
							<li>
								One of our 2,000+ licensed and hand-picked hearing care specialists in the U.S. will then
								<strong> personalize your hearing aids</strong> to your individual hearing needs. Our quality hearing
								aids and awesome hearing professionals guarantee your best outcome, which is what makes hear.com the #1
								hearing care provider in America.
							</li>
							<li>
								<strong>Try your awesome new hearing aids at no-risk for 45 days.</strong> Not only are they virtually
								invisible when you’re wearing them, they also provide superior speech clarity and come with smartphone
								connectivity. Some models are even rechargeable (not tiny batteries to change) and equipped with
								Bluetooth technology, too.
							</li>
						</ol>
					</div>
				</div>
			</section>

			<section className="o-row-7">
				<div className="o-wrapper ">
					<div className="o-row-2">
						<div className="c-fam-divider"></div>
					</div>
					<div className="o-row-2">
						<p className="c-fam-content-title">Take this important step today!</p>
					</div>
					<div className="o-row-6p5">
						<p className="c-fam-copy">
							Take advantage of being a part of our extended family and experience the difference hearing aids will make
							in your daily life today, and every day.
						</p>
					</div>
					<div className="o-row">
						<div className="o-flush-mobile">
							<picture>
								<source
									media="(min-width: 769px)"
									srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/family/group-desk.jpg"
								/>
								<source
									media="(max-width: 768px)"
									srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/family/group.jpg"
								/>
								<img
									className="o-fluid-img"
									srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/family/group-desk.jpg"
									alt="hearing aids"
									loading="lazy"
								/>
							</picture>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default Family;
